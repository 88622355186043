// SPDX-License-Identifier: AGPL-3.0-or-later
// SPDX-FileCopyrightText: 2020-2024 grommunio GmbH

export const ABOUT_DATA_RECEIVED = 'ABOUT_DATA_RECEIVED';

export const ANTISPAM_DATA_RECEIVED = 'ANTISPAM_DATA_RECEIVED';

export const ALIASES_DATA_RECEIVED = 'ALIASES_DATA_RECEIVED';
export const ALIAS_DATA_ADD = 'ALIAS_DATA_ADD';

export const AUTH_AUTHENTICATED = 'AUTH_AUTHENTICATED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';

export const CLASSES_DATA_RECEIVED = 'CLASSES_DATA_RECEIVED';
export const CLASSES_TREE_RECEIVED = 'CLASSES_TREE_RECEIVED';
export const CLASSES_SELECT_RECEIVED = 'CLASSES_SELECT_RECEIVED';
export const CLASSES_DATA_DELETE = 'CLASSES_DATA_DELETE';
export const CLASSES_DATA_ADD = 'CLASSES_DATA_ADD';

export const CREATE_PARAMS_DATA_RECEIVED = 'CREATE_PARAMS_DATA_RECEIVED';

export const DASHBOARD_DATA_RECEIVED = 'DASHBOARD_DATA_RECEIVED';

export const DBCONF_DATA_RECEIVED = 'DBCONF_DATA_RECEIVED';
export const DBCONF_SERVICE_ADD = 'DBCONF_SERVICE_ADD';
export const DBCONF_SERVICE_DELETE = 'DBCONF_SERVICE_DELETE';

export const DRAWER_EXPAND = 'DRAWER_EXPAND';
export const DRAWER_OPEN = 'DRAWER_OPEN';
export const DRAWER_DOMAINS_REVEICED = 'DRAWER_DOMAINS_REVEICED';
export const SELECT_DRAWER_DOMAIN = 'SELECT_DRAWER_DOMAIN';

export const DOMAIN_DATA_ADD = 'DOMAIN_DATA_ADD';
export const DOMAIN_DATA_EDIT = 'DOMAIN_DATA_EDIT';
export const DOMAIN_DATA_RECEIVED = 'DOMAIN_DATA_RECEIVED';
export const DOMAIN_DATA_DELETE = 'DOMAIN_DATA_DELETE';
export const DOMAIN_NEXT_SET = 'DOMAIN_NEXT_SET';

export const FOLDERS_DATA_RECEIVED = 'FOLDERS_DATA_RECEIVED';
export const FOLDERS_TREE_RECEIVED = 'FOLDERS_TREE_RECEIVED';
export const FOLDER_DATA_ADD = 'FOLDER_DATA_ADD';
export const FOLDER_DATA_DELETE = 'FOLDER_DATA_DELETE';
export const FOLDERS_NEXT_SET = 'FOLDERS_NEXT_SET';

export const FORWARDS_DATA_RECEIVED = 'FORWARDS_DATA_RECEIVED';

export const GROUPS_DATA_RECEIVED = 'GROUPS_DATA_RECEIVED';
export const GROUP_DATA_DELETE = 'GROUP_DATA_DELETE';
export const GROUP_DATA_EDIT = 'GROUP_DATA_EDIT';
export const GROUP_DATA_ADD = 'GROUP_DATA_ADD';

export const LDAP_DATA_RECEIVED = 'LDAP_DATA_RECEIVED';
export const LDAP_DATA_CLEAR = 'LDAP_DATA_CLEAR';

export const LICENSE_DATA_RECEIVED = 'LICENSE_DATA_RECEIVED';

export const LOGS_DATA_RECEIVED = 'LOGS_DATA_RECEIVED';

export const ORGS_DATA_RECEIVED = 'ORGS_DATA_RECEIVED';
export const ORG_DATA_ADD = 'ORG_DATA_ADD';
export const ORG_DATA_DELETE = 'ORG_DATA_DELETE';

export const ORPHANED_USERS_RECEIVED = 'ORPHANED_USERS_RECEIVED';
export const ORPHANS_DELETED = 'ORPHANS_DELETED';

export const OWNERS_DATA_RECEIVED = 'OWNERS_DATA_RECEIVED';
export const OWNER_DATA_ADD = 'OWNER_DATA_ADD';
export const OWNER_DATA_DELETE = 'OWNER_DATA_DELETE';

export const PERMISSIONS_DATA_RECEIVED = 'PERMISSIONS_DATA_RECEIVED';

export const PROFILE_DATA_RECEIVED = 'PROFILE_DATA_RECEIVED';

export const ROLES_DATA_RECEIVED = 'ROLES_DATA_RECEIVED';
export const ROLE_DATA_ADD = 'ROLE_DATA_ADD';
export const ROLE_DATA_DELETE = 'ROLE_DATA_DELETE';
export const ROLES_NEXT_SET = 'ROLES_NEXT_SET';

export const SERVERS_DATA_RECEIVED = 'SERVERS_DATA_RECEIVED';
export const SERVERS_DATA_DELETE = 'SERVERS_DATA_DELETE';
export const SERVERS_DATA_ADD = 'SERVERS_DATA_ADD';
export const SERVERS_POLICY_RECEIVED = 'SERVERS_POLICY_RECEIVED';

export const SERVICES_DATA_RECEIVED = 'SERVICES_DATA_RECEIVED';

export const SPAM_DATA_RECEIVED = 'SPAM_DATA_RECEIVED';
export const SPAM_THROUGHPUT_RECEIVED = 'SPAM_THROUGHPUT_RECEIVED';
export const SPAM_HISTORY_RECEIVED = 'SPAM_HISTORY_RECEIVED';

export const SYNC_DATA_RECEIVED = 'SYNC_DATA_RECEIVED';

export const TASK_DATA_RECEIVED = 'TASK_DATA_RECEIVED';
export const TASK_STATUS_RECEIVED = 'TASK_STATUS_RECEIVED';

export const TOKEN_REFRESH = "TOKEN_REFRESH";

export const SERVER_CONFIG_SET = 'SERVER_CONFIG';
export const SERVER_DNS_CHECK = "SERVER_DNS_CHECK";

export const USER_DATA_ADD = 'USER_DATA_ADD';
export const USER_DATA_DELETE = 'USER_DATA_DELETE';
export const USERS_DATA_RECEIVED = 'USERS_DATA_RECEIVED';
export const USER_ALIASES_DATA_RECEIVED = 'USER_ALIASES_DATA_RECEIVED';
export const USER_ALIAS_DATA_ADD = 'USER_ALIAS_DATA_ADD';
export const USER_ALIAS_DATA_DELETE = 'USER_ALIAS_DATA_DELETE';
export const USERS_NEXT_SET = 'USERS_NEXT_SET';
export const USERS_SYNC_RECEIVED = 'USERS_SYNC_RECEIVED';

export const VHOST_DATA_RECEIVED = 'VHOST_DATA_RECEIVED';
